"use strict";

exports.__esModule = true;
exports.FireCallbackInEffect = FireCallbackInEffect;

var _react = require("react");

/*
 * Calls callback in an effect and renders children
 */
function FireCallbackInEffect({
  children,
  callback
}) {
  (0, _react.useEffect)(() => {
    callback();
  });
  return children;
}